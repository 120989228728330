import React from 'react'
import { Navigation } from './components/Navigation/Navigation'

function App() {
  return (
    <>
      <Navigation />
    </>
  )
}

export default App
